import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-content',
  templateUrl: './third-content.component.html',
  styleUrls: ['./third-content.component.scss']
})
export class ThirdContentComponent implements OnInit {
  imgSrc:string;
  constructor() { }

  ngOnInit(): void {
  }

 
  onClick(event){
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

}
