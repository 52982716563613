<body class="header container-fluid">
    
    <div class="container">
        <div class="row titlPage align-items-center ">
            <div class="col ">
                <div class="displayChangeText">
                    <h1 class="mb-1 ">TROCA DE TELA</h1>
                    <p class="eachModelText">Fazemos a troca para todos os modelos</p>
                    <h1>EM ATÉ 1H</h1>
                    <a href="https://wa.me/+555521999563750?text=Ol%C3%A1"><button >Confira aqui</button></a>
                </div>
            </div>
            <div class="col cellMobile d-flex flex-wrap justify-content-center"><img src="../../assets/01.png" alt=""></div>
        </div>
        <div class="row">
            <div class=" col w-100 text-center">
                <h2> Somos a melhor assistência técninca especializada</h2>
            </div>
        </div>

        <div class="row flex-wrap">
            <div class="col d-flex justify-content-center mt-3">
                <div class="card">
                    <span class="text-center">
                        <img src="../../assets/star.png" alt="star">
                        <h2 class="primaryText">5 Estrelas</h2>
                        <h3 style="margin: 0px;" class="secondaryText ">Top em avaliações</h3>
                        <h4 class="tertiaryText">Avaliação máxima no google e facebook</h4>
                    </span>
                </div>
            </div>
            <div class="col d-flex justify-content-center mt-3">
                <div class="card">
                    <span>
                        <img src="../../assets/sta2.png" alt="star">
                        <h2 class="primaryText">Garantia</h2>
                        <h3 style="margin: 0px;" class="secondaryText ">Premium</h3>
                        <h4 class="tertiaryText">até 1 ano de garantia</h4>
                    </span>
                </div>
            </div>
            <div class="col d-flex justify-content-center mt-3">
                <div class="card">
                    <span>
                        <img src="../../assets/cartão.png" alt="star">
                        <h2 class="primaryText">Melhor preço</h2>
                        <h3 style="margin: 0px;" class=" secondaryText ">Até 12x</h3>
                        <h4 class="tertiaryText">pague em até 12x sem juros</h4>
                    </span>
                </div>
            </div>
            <div class="col d-flex justify-content-center mt-3">
                <div class="card">
                    <span>
                        <img src="../../assets/car.png" alt="star" class="img">
                        <h2 class="primaryText">Você perto da loja</h2>
                        <h3 style="margin: 0px;" class="secondaryText mb-2">Rio de Janeiro</h3>
                        <h4 class="tertiaryText">Buscamos seu aparelho</h4>
                    </span>
                </div>
            </div>
        </div>
        <div class="row numbertext">
            <div class="col mt-3 text-center">
                <div class="secondBox_box ">
                    <!-- <h1 style="font-size: 50px;"><strong>{{touch}}</strong></h1> -->
                    <h1 style="font-size: 50px;"><strong>2640</strong></h1>
                    <div class="secondBox_greyInfo">Telas trocadas</div>
                </div>
            </div>
            <div class="col mt-3 text-center">
                <div class="secondBox_box">
                    <!-- <h1 style="font-size: 50px;" ><strong>{{Repar}}</strong></h1> -->
                    <h1 style="font-size: 50px;" ><strong>1550</strong></h1>
                    <div class="secondBox_greyInfo">Reparos de placa</div>
                </div>
            </div>
            <div class="col mt-3 text-center">
                <div class="secondBox_box">
                    <!-- <h1 style="font-size: 50px;"><strong>{{client}}</strong></h1> -->
                    <h1 style="font-size: 50px;"><strong>5542</strong></h1>
                    <div class="secondBox_greyInfo">Clientes satisfeitos</div>
                </div>
            </div>
        </div>
    </div>
</body>