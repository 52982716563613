<body>
  <div class="header">

    <div class="contact">
      <h1 class="mb-5 title">
        <strong>Faça seu orçamento grátis</strong>
      </h1>
      <div class="contactInput">
        <div class="gap">
          <mat-form-field class="input" appearance="fill">
            <mat-label>Nome</mat-label>
            <input matInput id="name" placeholder="Digite seu nome..." value="" autocomplete="off" type="text"
              name="name" id="name">
          </mat-form-field>
          <mat-form-field class="input" appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput type="email" id="email" placeholder="Digite seu email..." value="" autocomplete="off"
              type="text" id="email">
          </mat-form-field>
        </div>
        <div class="gap">
          <mat-form-field class="input" appearance="fill">
            <mat-label>Marca</mat-label>
            <input matInput type="text" id="email" placeholder="Digite a marca do aparelho..." value=""
              autocomplete="off" type="text" id="apar">
          </mat-form-field>
          <mat-form-field class="input" appearance="fill">
            <mat-label>Smartphone</mat-label>
            <input matInput placeholder="Digite o nome do aparelho..." value="" autocomplete="off" type="text"
              id="smart">
          </mat-form-field>
        </div>
        <div class="description">
          <mat-form-field class="description" appearance="fill">
            <mat-label>Defeito</mat-label>
            <textarea matInput placeholder="Digite o defeito do aparelho.." id="description"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="button">
        <button mat-raised-button color="primary" type="button" (click)="whatSend()">Enviar via Whatsapp</button>
      </div>
    </div>
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7346.498440638823!2d-43.19079739999997!3d-22.97786129999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x512e5c1c91c3393c!2sLabPhone%20-%20Assist%C3%AAncia%20T%C3%A9cnica!5e0!3m2!1spt-BR!2sbr!4v1669377692427!5m2!1spt-BR!2sbr"
        min-width="400px" class="mapp" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div>
        <address>
          <strong>LabPhone - Assistência Técnica:</strong> <br>
          Rua São Cristóvão, 601 - Box 01<br>
          Rio de Janeiro - São Cristóvão,<br>
          RJ, 20940-001
        </address>
      </div>

    </div>
  </div>

</body>