import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {

  countrycode = "+5521";
  whatNumber = "951012995";
  url = "";


  constructor() { }

  ngOnInit(): void {

  }

  whatSend() {
    let name = (document.getElementById('name') as HTMLInputElement).value;
    let email = (document.getElementById('email') as HTMLInputElement).value;
    let apar = (document.getElementById('apar') as HTMLInputElement).value;
    let smart = (document.getElementById('smart') as HTMLInputElement).value;
    let description = (document.getElementById('description') as HTMLInputElement).value;

    this.url = "http://wa.me/" + this.countrycode + this.whatNumber + "?text="
      + "*Nome:* " + name + "%0a"
      + "*Email:* " + email + "%0a"
      + "*Marca:* " + apar + "%0a"
      + "*Smartphone:* " + smart + "%0a%0a"
      + "*Descrição:* " + description;

    window.open(this.url, '_blank').focus();

  }

}
