import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  toHome(){
    document.getElementById("home").scrollIntoView({behavior:"smooth"});

  }
  toService(){
    document.getElementById("service").scrollIntoView({behavior:"smooth"});

  }
  toAcesso(){
    document.getElementById("acessorio").scrollIntoView({behavior:"smooth"});

  }

  toContact(){
    document.getElementById("contact").scrollIntoView({behavior:"smooth"});

  }

}
