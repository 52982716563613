import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  contar:number = 0;
  
  client = 0;
  Repar = 0;
  touch = 0;


  // zstop:any = setInterval(() => {
  //   this.client++;
  //   if(this.client == 5542)
  //   {
  //     clearInterval(this.zstop);
  //   }
  // }, 10)

  // ystop:any = setInterval(() => {
  //   this.Repar++;
  //   if(this.Repar == 1550)
  //   {
  //     clearInterval(this.ystop);
  //   }
  // }, 10)
  // xstop:any = setInterval(() => {
  //   this.touch++;
  //   if(this.touch == 2640)
  //   {
  //     clearInterval(this.xstop);
  //   }
  // }, 10)

 
  constructor() {}

  ngOnInit() {
  
  }

  
 



}

