import { Component, OnInit,HostListener } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


  }

  @HostListener("document:scroll")
  scrollNav(){
    if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
      document.getElementById("navbar").style.backgroundColor = "black";
      
    }else {
      document.getElementById("navbar").style.backgroundColor = "rgba(17, 25, 40, 0.75)";

    }
  }


  toHome(){
    document.getElementById("home").scrollIntoView({behavior:"smooth"});
    this.menuBtn();
  }
  toService(){
    document.getElementById("service").scrollIntoView({behavior:"smooth"});
    this.menuBtn();
  }
  toAcesso(){
    document.getElementById("acessorio").scrollIntoView({behavior:"smooth"});
    this.menuBtn();
  }

  toContact(){
    document.getElementById("contact").scrollIntoView({behavior:"smooth"});
    this.menuBtn();
  }


  menuBtn(){
     const nav = document.getElementById ('nav');
     nav.classList.toggle('active');

  }





}
