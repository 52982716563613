<footer>
    <div class="header">
        <div class="sobre">
            <h1>Sobre</h1>
            <p>A LabPhone é uma empresa particular e independente, NÃO somos Autorizados e nem representamos nenhuma
                marca ou fabricante. Somos uma assistência Multi Marca e não atendemos garantia do fabricante nem
                garantia estendida.</p>
        </div>
        <div class="menu">
            <h1>Menu</h1>
            <li class="nav-item"><a (click)="toHome()" routerLinkActive="active"> Home</a></li>
            <li class="nav-item"><a (click)="toService()" routerLinkActive="active"> Serviços</a></li>
            <li class="nav-item"><a (click)="toAcesso()" routerLinkActive="active"> Galeria</a></li>
            <li class="nav-item"><a (click)="toContact()" routerLinkActive="active"> Contato</a></li>
        </div>
        <div class="contact">
            <h1>Contato</h1>
            <p>LabPhone - Assistência Técnica</p>
            <br>
            <p>Endereço: R. São Cristóvão, 601 - Box 01 - São Cristóvão, Rio de Janeiro - RJ, 20940-001</p>
        </div>
        <div class="logo">
            <a href="#">
                <img class="logotipo" src="../../assets/logolab.svg" alt="Logo">
            </a>
            <div class="link">
                <li class="nav-item"><a href="https://www.facebook.com/labphonerj" target="_blank"><img class="face"
                            src="../../assets/face.svg" alt="facebook"></a></li>
                <li class="nav-item"><a href="https://www.instagram.com/labphonerj" target="_blank"><img class="insta"
                            src="../../assets/insta.svg" alt="instagram"></a></li>
            </div>
        </div>
    </div>
    <div class="copy">
        <span>© Copyright 2022. Novatek. Todos os direitos reservados. Rio de Janeiro, Brasil.</span>
    </div>
</footer>