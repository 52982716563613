<body>
    <!-- background -->
   
    <div class="back">
        <h1>Galeria</h1>

        <div>

            <div class="image-container mb-5">
                
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"> <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab1.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" src="../../assets/lab1.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab2.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab2.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab3.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab3.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"> <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab4.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" src="../../assets/lab4.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab5.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab5.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab6.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab6.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"> <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab7.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" src="../../assets/lab7.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab8.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab8.jpg"/></div>
                <div class="image" style="--clip-start: ellipse(0 0 at 0 0); --clip-end: ellipse(150% 150% at 0 0);"><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab9.jpg"/><img (click)="onClick($event)" data-toggle="modal" data-target="#myModal"  src="../../assets/lab9.jpg"/></div>
              </div>
        </div>


    </div>

</body>


<!-- Modal -->
<div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
      <div class="">
        
        <div class="">
            
        <img
        src="{{imgSrc}}"
        style="max-width:100%;  cursor:pointer"
        class="modal-hover-opacity"
      /> 
        </div>
      </div>
    </div>
    </div>
