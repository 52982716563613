<div class="body">
    <h1 class="servicesTitle">Serviços</h1>
    <div class="container d-flex align-items-center justify-content-center flex-column pb-5">
        <div class="organizeBox">
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/smartphone.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Troca de tela</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/bateria.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Troca de baterias</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/pingo.png" alt="contentBox">
                    
                </div>
                <p class="mt-3 imgText">Desoxidação de celular</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/icons8-reiniciar-100 1.png" alt="contentBox">
                    
                </div>
                <p class="mt-3 imgText">Restaurações de Software</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/software.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Atualização</p>
            </div>

            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/icons8-volume-alto-100 1.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Auto Falante e microfone</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/icons8-mini-usb-b-100 1.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Troca de conectores</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/att.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Reparo na placa principal</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/icons8-usb-cable-64 1.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Troca do cabo flex</p>
            </div>
            <div class="content">
                <div class="contentBox">
                    <img src="../../assets/icons8-desbloquear-2-100 1.png" alt="contentBox">
                </div>
                <p class="mt-3 imgText">Desbloqueio do celular</p>
            </div>
        </div>
 
    </div>
    <p class="text-center ourServices pb-5">
        Nossos serviços antendem aparelhos:
    </p>
    <div class="w-100 d-flex align-items-center justify-content-center">
        
<div class="slider">
	<div class="slide-track">

		<div class="slide">
			<img src="../../assets/iphone.svg" height="100" width="100" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/samsung.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/xiaomi.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/motorola.svg" height="100" width="250" alt="" />
		</div>
        <div class="slide">
			<img src="../../assets/iphone.svg" height="100" width="100" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/samsung.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/xiaomi.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/motorola.svg" height="100" width="250" alt="" />
		</div>
        <div class="slide">
			<img src="../../assets/iphone.svg" height="100" width="100" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/samsung.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/xiaomi.svg" height="100" width="250" alt="" />
		</div>
		<div class="slide">
			<img src="../../assets/motorola.svg" height="100" width="250" alt="" />
		</div>

		
	</div>
</div>
    </div>
</div>