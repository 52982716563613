

    <header id="navbar" class="d-flex navbar navbar-expand-sm fixed-top navbar-scroolled ">
        <a href="#">
            <img class="logo" src="../../assets/logolab.svg" alt="Logo">
        </a>

        <nav id="nav">
            <button id="btn-mobile" (click)="menuBtn()"><span id="close"></span></button>
            <ul id="menu">
                <li class="nav-item"><a (click)="toHome()" routerLinkActive="active"> Home</a></li>
                <li class="nav-item"><a (click)="toService()" routerLinkActive="active"> Serviços</a></li>
                <li class="nav-item"><a (click)="toAcesso()" routerLinkActive="active"> Galeria</a></li>
                <li class="nav-item"><a (click)="toContact()" routerLinkActive="active"> Contato</a></li>
                <li class="nav-item"><a href="https://www.facebook.com/labphonerj" target="_blank" ><img class="face" src="../../assets/face.svg" alt="facebook"></a></li>
                <li class="nav-item"><a href="https://www.instagram.com/labphonerj" target="_blank" ><img class="insta" src="../../assets/insta.svg" alt="instagram"></a></li>

            </ul>
        </nav>
    </header>



