
<app-navbar></app-navbar>
<section id="home">
    <app-body></app-body>
</section>
<section id="service">
    <app-second-content></app-second-content>
</section>
<section id="acessorio">
    <app-third-content></app-third-content>
</section>

<section id="contact">
    <app-budget></app-budget>
</section>
<app-footer></app-footer>
